import {supportedRequestMethods, requestWrapper} from '../API';
import {AUTHENTICATE, GET_PROFILE, VERIFY_AUTH, LOGOUT, GENERATE_SUBSCRIPTION} from './types';
import AppError from '../models/AppError';
import i18n from '../localization/config';

export const verifySession = () => async dispatch => {
	let requestParams = {
		method: supportedRequestMethods.POST,
		endpoint: '/client/verify-authentication',
	};

	let response = await requestWrapper(requestParams);

	dispatch({type: VERIFY_AUTH, authenticated: !!response?.success});

	if (!!response?.success) {
		dispatch(getProfile());
	}
};

export const sendOTP = (phoneNumber) => async (dispatch) => {
	let requestParams = {
		method: supportedRequestMethods.POST,
		endpoint: `/client/login`,
		body: {
			phone: phoneNumber
		}
	};

	const response = await requestWrapper(requestParams);

	if (!response?.success) {
		throw new AppError({
			message: i18n.t(`errors.server_errors.${response?.data?.errors[0]?.errorCode}`, {defaultValue: i18n.t(`errors.server_errors.A0`)}),
			code: response?.data?.errors[0]?.errorCode ? response?.data?.errors[0]?.errorCode : 'A0',
		});
	}
};

export const login = (phone, otp) => async dispatch => {

	let requestParams = {
		method: supportedRequestMethods.POST,
		endpoint: `/client/login`,
		body: {phone, otp},
	};

	let response = await requestWrapper(requestParams);

	console.log('response');
	console.log(response);

	if (response?.success) {
		dispatch({
			type: AUTHENTICATE,
		});

		dispatch(getProfile());
	} else {
		throw new AppError({
			message: i18n.t(`errors.server_errors.${response?.data?.errors[0]?.errorCode}`, {defaultValue: i18n.t(`errors.server_errors.A0`)}),
			code: response?.data?.errors[0]?.errorCode ? response.data.errors[0].errorCode : 'A0',
		});
	}
};

export const logout = () => async (dispatch) => {
	let requestParams = {
		method: supportedRequestMethods.POST,
		endpoint: `/client/logout`,
	};

	await requestWrapper(requestParams);

	dispatch({type: LOGOUT});
};

const getProfile = () => async dispatch => {

	let requestParams = {
		method: supportedRequestMethods.GET,
		endpoint: '/client/profile',
	};

	let response = await requestWrapper(requestParams);

	if (response?.success) {
		dispatch({
			type: GET_PROFILE,
			profile: response.data.data
		});
	} else {
		throw new AppError({
			message: i18n.t(`errors.server_errors.${response?.data?.errors[0]?.errorCode}`, {defaultValue: i18n.t(`errors.server_errors.A0`)}),
			code: response?.data?.errors[0]?.errorCode ? response.data.errors[0].errorCode : 'A0',
		});
	}
};

export const subscribe = (token) => async dispatch => {

	let requestParams = {
		method: supportedRequestMethods.POST,
		endpoint: '/client/subscription',
		body: {
			buyer_key: token
		}
	};

	let response = await requestWrapper(requestParams);

	if (response?.success) {
		dispatch({type: GENERATE_SUBSCRIPTION});
	} else {
		throw new AppError({
			message: i18n.t(`errors.server_errors.${response?.data?.errors[0]?.errorCode}`, {defaultValue: i18n.t(`errors.server_errors.A0`)}),
			code: response?.data?.errors[0]?.errorCode ? response.data.errors[0].errorCode : 'A0',
		});
	}
};
