import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import store from './redux/store';
import {Provider} from 'react-redux';
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import {heIL} from '@material-ui/core/locale';
import {create} from 'jss';
import rtl from 'jss-rtl';
import {StylesProvider, jssPreset} from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	environment: process.env.REACT_APP_ENV,
	integrations: [
		new Integrations.BrowserTracing(),
	],
	tracesSampleRate: 1.0,
});

const theme = createMuiTheme({
	direction: 'rtl',
	palette: {
		primary: {
			main: '#3ec7af',
			contrastText: '#ffffff' //button text white instead of black

		},
	},
}, heIL);

const jss = create({plugins: [...jssPreset().plugins, rtl()]});

ReactDOM.render(
	<React.StrictMode>
		<StylesProvider jss={jss}>

			<ThemeProvider theme={theme}>
				<Provider store={store}>
					<App/>
				</Provider>
			</ThemeProvider>
		</StylesProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
