import {
	VERIFY_AUTH,
	AUTHENTICATE,
	GENERATE_SUBSCRIPTION,
	GET_PROFILE, LOGOUT,
} from './types';

const initialState = {
	verifyingAuth: true,
	authenticated: false,
	userProfile: null,
	clientIsPremium: false,
	paymentSuccess: null,
};

export default function (state = initialState, action) {
	switch (action.type) {
	case VERIFY_AUTH:
		return {
			...state,
			verifyingAuth: false,
			authenticated: action.authenticated
		};
	case AUTHENTICATE:
		return {
			...state,
			authenticated: true,
			verifyingAuth: false
		};
	case GET_PROFILE:
		return {
			...state,
			userProfile: action.profile
		};
	case GENERATE_SUBSCRIPTION:
		return {
			...state,
			paymentSuccess: true
		};
	case LOGOUT:
		return initialState;
	default:
		return state;
	}
}
