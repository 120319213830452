/**
 * A custom error class that indicates an app-related error.
 */
class AppError extends Error {

	/**
	 * Constructor for the app error
	 *
	 */
	constructor({message, display, data, code}) {
		super();
		this.message = message;
		this.display = display ? display : false;
		this.data = data ? data : {};

		if (code)
			this.code = code;
	}
}

export default AppError;
