export default {
	defaultStyle: {
		base: {
			'font-size': '16px',
			'text-align': 'right',
			'::placeholder': {
				'text-align': 'right',
				'color': 'rgba(0, 0, 0, 0.54)',
			},
		},
		invalid: {
		},
		valid: {
			color: '#000',
		},
	},
};
