import React from 'react';
import {Button} from '@material-ui/core';
import './Home.css';
import {ReactComponent as PremiumIcon} from '../../assets/images/premium.svg';
import {ReactComponent as Checkmark} from '../../assets/images/checkmark_circle.svg';
import i18n from '../../localization/config';
import {
	Link,
} from 'react-router-dom';

const Home = props => {
	return (
		<div
			className="pageContainer"
		>
			<div className="contentContainer">
				<div
					className="heading"
				>
					<PremiumIcon/>
					<div
						className="headingTitle"
					>
						{i18n.t('home.title')}
					</div>
				</div>

				<div
					className="benefitsSection"
				>
					<div
						className="benefitsTitle"
					>
						{i18n.t('home.premiumBenefits')}
					</div>
					{
						i18n.t('home.benefits').map(benefits =>
							<span
								key={benefits}
								dir="rtl"
								className="benefit"
							>
								<div>
									<Checkmark style={{width: 17, height: 17}}/>
								</div>
								<span
									className="benefitText"
								>
									{benefits}
								</span>
							</span>
						)
					}
				</div>

				<div
					className="disclaimerSection"
					dir="rtl"
				>
					{i18n.t('home.disclaimer')}
				</div>

				<Link to='/authentication'>
					<Button variant="contained" color="primary" className="premiumButton">
						<div
							className="buttonText"
						>
							<div className="premiumText">{i18n.t('home.premiumSubscription')}</div>
							<div>{i18n.t('home.premiumSubscriptionPrice')}</div>
						</div>
					</Button>
				</Link>
			</div>
		</div>
	);
};

export default Home;
