import React from 'react';
import i18n from '../../localization/config';
import './EndProcess.css';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from '@material-ui/core';
import {logout} from '../../redux/actions';
import {Redirect} from 'react-router-dom';

const EndProcess = props => {
	const dispatch = useDispatch();
	const clientProfile = useSelector(state => state.userProfile);

	if (clientProfile && (!clientProfile.premium && clientProfile.profileCreated)) {
		return (
			<Redirect to="/payment"/>
		);
	}

	return (
		<div
			className="pageContainer"
		>
			<div className="contentContainer">
				<div
					className="heading"
				>
					<div
						className="headingTitle"
					>
						{i18n.t('endProcess.title')}
					</div>
				</div>

				<div className="body">
					{clientProfile?.premium && (
						<span>{i18n.t('endProcess.already_premium')}</span>
					)}
					{!clientProfile?.profileCreated && (
						<span>{i18n.t('endProcess.profile_required')}</span>
					)}
				</div>

				<Button
					variant="contained"
					color="primary"
					className="premiumButton"
					onClick={() => dispatch(logout())}
				>
					להתנתק
				</Button>
			</div>
		</div>
	);
};

export default EndProcess;
