import React, {useState} from 'react';
import {CircularProgress, Button, TextField, MenuItem} from '@material-ui/core';
import {useDispatch,} from 'react-redux';
import './Authentication.css';
import i18n from '../../localization/config';
import {Formik} from 'formik';
import validator from 'validator';
import {login, sendOTP} from '../../redux/actions';
import OtpInput from 'react-otp-input';
import * as Sentry from '@sentry/react';

const Authentication = props => {
	const dispatch = useDispatch();
	const [serverError, setServerError] = useState('');
	const [phonePhase, setPhonePhase] = useState(true);
	const [OTPExpired, setOTPExpired] = useState(false);
	const [OTPValid, setOTPValid] = useState(false);
	const phoneNumberCountryCode = '+972';

	const sendOTPtoClient = async (phone) => {
		setOTPExpired(false);
		setServerError('');
		try {
			await dispatch(sendOTP(phone));
			setPhonePhase(false);
		} catch (err) {
			setServerError(err.message);
			Sentry.captureException({data: err});
		}
	};

	const authenticate = async (phone, otp) => {
		try {
			await dispatch(login(phone, otp));
		} catch (err) {
			setServerError(err.message);
			if (err.code === 'A19' || err.code === 'A18') {
				setOTPExpired(true);
			} else {
				Sentry.captureException({data: err});
			}
		}
	};

	return (
		<div
			className="pageContainer"
		>
			<div className="contentContainer">
				<div className="heading">
					{phonePhase ? i18n.t('authentication.heading_phone') : i18n.t('authentication.heading_otp')}
				</div>

				<div className="subheading">
					{phonePhase ? i18n.t('authentication.subheading_phone') : i18n.t('authentication.subheading_otp')}
				</div>

				<Formik
					initialValues={{phoneNumber: '', otp: ''}}
					validate={values => {
						const errors = {};
						setServerError(''); // Reset server error
						if (!values.phoneNumber) {
							errors.phoneNumber = i18n.t('required_field');
						} else if (
							!validator.isMobilePhone(phoneNumberCountryCode + values.phoneNumber, ['he-IL'], {strictMode: true})
						) {
							errors.phoneNumber = i18n.t('authentication.form.provide_valid_phone');
						}

						if (!phonePhase) {
							if (!values.otp) {
								errors.otp = i18n.t('required_field');
								setOTPValid(false);
							} else if (values.otp.length !== 4) {
								errors.otp = i18n.t('authentication.form.provide_valid_code');
								setOTPValid(false);
							} else {
								setOTPValid(true);
							}
						}

						return errors;
					}}
					onSubmit={async (values, {setSubmitting}) => {
						if (phonePhase) {
							await sendOTPtoClient(phoneNumberCountryCode + values.phoneNumber);
						} else {
							await authenticate(phoneNumberCountryCode + values.phoneNumber, values.otp);
						}
						setSubmitting(false);
					}}
				>
					{({
						isSubmitting,
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						setValues
					}) => (
						<form className="form" noValidate onSubmit={handleSubmit}>
							<div
								className="formFields"
							>
								{phonePhase ? (
									<div>
										<div
											className="phoneRow"
										>
											<TextField
												label={i18n.t('authentication.form.phonePlaceholder')}
												variant="outlined"
												helperText={errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : ''}
												error={(errors.phoneNumber && touched.phoneNumber) || !!serverError}
												required
												name="phoneNumber"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.phoneNumber}
												inputProps={{
													maxLength: 9
												}}
												fullWidth
											/>
											<TextField
												className="countryCodeField"
												select
												variant="outlined"
												value={phoneNumberCountryCode}
												onChange={handleChange}
												defaultValue={phoneNumberCountryCode}
												dir="ltr"
											>
												<MenuItem key={phoneNumberCountryCode} value={phoneNumberCountryCode}>
													{phoneNumberCountryCode}
												</MenuItem>
											</TextField>
										</div>
										<div className="MuiFormHelperText-root">
											{i18n.t('authentication.form.phoneHelperText')}
										</div>
									</div>
								) : (
									<div>
										<OtpInput
											name="otp"
											value={values.otp}
											onChange={(val) => {
												setValues({
													...values,
													otp: val
												});
											}}
											numInputs={4}
											inputStyle="otpInput"
											containerStyle="otpContainer"
											focusStyle="otpFocus"
											errorStyle="otpError"
											hasErrored={serverError}
											isInputNum
											dir="rtl"
											isDisabled={OTPExpired}
										/>
										<div className="textSpacer">
											<a
												onClick={() => setPhonePhase(true)}
												className="textButton"
											>
												{i18n.t('authentication.changePhoneNumber')}
											</a>
										</div>
										{OTPExpired && (
											<div className="textSpacer">
												<a
													onClick={() => {
														setValues({
															...values,
															otp: ''
														});
														sendOTPtoClient(phoneNumberCountryCode + values.phoneNumber);
													}}
													className="textButton"
												>
													{i18n.t('authentication.resendOTP')}
												</a>
											</div>
										)}
									</div>
								)}
							</div>
							{serverError && (
								<div className="errorText serverError">
									{serverError}
								</div>
							)}
							{isSubmitting && (
								<div className="spinner">
									<CircularProgress/>
								</div>
							)}
							<Button
								variant="contained"
								color="primary"
								className="premiumButton"
								type="submit"
								disabled={isSubmitting || (!phonePhase && !OTPValid) || OTPExpired}
							>
								<div className="premiumText">
									{phonePhase ? i18n.t('authentication.sendOTP') : i18n.t('authentication.confirm')}
								</div>
							</Button>
						</form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default Authentication;
