import React, {useEffect} from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from 'react-router-dom';
import Authentication from '../../pages/authentication/Authentication';
import Payment from '../../pages/payment/Payment';
import {useSelector, useDispatch} from 'react-redux';
import {verifySession} from '../../redux/actions';
import Home from '../../pages/home/Home';
import EndProcess from '../../pages/end-process/EndProcess';

const App = props => {
	const dispatch = useDispatch();
	const authenticated = useSelector(state => state.authenticated);

	// Load PayMe Hosted fields
	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://cdn.paymeservice.com/hf/v1/hostedfields.js';
		script.async = true;
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);


	useEffect(() => {
		// init();
		dispatch(verifySession()); // TODO - Do something if server is dead
	}, [dispatch]);

	const renderRoutes = () => {
		if (!authenticated) {
			return (
				<>
					<Route exact path="/" component={Home}/>
					<Route path="/authentication" component={Authentication}/>
					<Route path="*">
						<Redirect to="/"/>
					</Route>
				</>
			);
		} else {
			return (
				<>
					<Route exact path="/" component={EndProcess}/>
					<Route path="/payment" component={Payment}/>
					<Route path="*">
						<Redirect to="/payment"/>
					</Route>
				</>
			);
		}
	};

	/**
	 * TODO
	 * If not authenticated - login page
	 * if authenticated:
	 *  - If profile not created - end process page
	 *  - If client is already subscribed (trial/premium/admin premium) - end process page
	 *  - Else, payment page
	 */
	return (
		<Router>
			<Switch>
				{renderRoutes()}
			</Switch>
		</Router>
	);
};

export default App;
