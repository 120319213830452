const language = {
	required_field: 'שדה חובה',
	home: {
		title: 'חשבון פרמיום',
		premiumBenefits: 'היתרונות של פרימיום',
		premiumSubscription: 'מנוי פרמיום',
		premiumSubscriptionPrice: '₪19.90 /חודשי',
		automatic_resubscription_warning: 'מתחדש אוטומטית, בטל בכל עת',
		benefits: [
			'זיהוי צמחים ללא הגבלה יומית, וכל המידע על הצמח פתוח בפניך.',
			'גישה חופשית לכל המתכונים (למאכל ולמרפא), המאמרים והדרכות הליקוט המקוונות.',
			'צפיה במפה חיה של כל צמחי הבר לליקוט סביבך - כך שאפשר לגלות בקלות איפה נמצא צמח שמעניין אותך.',
			' גישה חופשית לכל המאמרים והדרכות שבאפליקציה.',
			'הנחות מיוחדות בהצטרפות לסיורי הליקוט, הסדנאות והקורסים.',
		],
		disclaimer: 'תודה ששקלת לשדרג את המנוי שלך למנוי פרימיום בתשלום סמלי. התשלום הזה מאפשר לנו להמשיך לשדרג את האפליקציה ולהכניס אליה עוד ועוד תכנים איכותיים עבורך.\n' +
			'אפשר לבטל את מנוי הפרימיום מתי שתרצו בקישור הזה או במשלוח הודעה אלינו ל info@lukit.app או דרך האפליקציה.\n' +
			'התשלום עבור רכישת חשבון פרימיום נעשה בהזנת פרטי כרטיס האשראי שלך בטופס מקוון מאובטח ברמה הגבוהה ביותר.\n' +
			'דמי המנוי לחשבון פרימיום הם חודשיים ומתחדשים באופן אוטומטי עד לביטולם על ידך.\n' +
			'תמיד אפשר לעצור את החיובים.\n' +
			'מידע נוסף נמצא בתנאי השירות ומדיניות הפרטיות.'
	},
	authentication: {
		heading_phone: 'התחבר',
		heading_otp: 'אימות קוד',
		subheading_phone: 'אנא הזינו את מס\' הטלפון שלכם כדי שנוכל לזהות אתכם',
		subheading_otp: 'שלחנו קוד אימות למס\' טלפון שסיפקתם על מנת שנוכל לדעת שזה אתם',
		changePhoneNumber: 'אני רוצה לשנות את מספר הטלפון',
		resendOTP: 'שלחו קוד שוב',
		sendOTP: 'שליחת קוד חד-פעמי',
		confirm: 'אישור',
		form: {
			phonePlaceholder: `מס' טלפון`,
			phoneHelperText: 'יש להשתמש במס\' הטלפון שבאמצעותו נרשמת באפליקציה.',
			otpPlaceholder: `קוד אימות`,
			provide_valid_phone: 'יש לספק מס\' טלפון תקני',
			provide_valid_code: 'יש לספק קוד בעל 4 ספרות תקני',
		}
	},
	endProcess: {
		title: 'לא ניתן להמשיך עם תהליך התשלום',
		already_premium: 'קיים מנוי על שם החשבון המזוהה.',
		profile_required: 'יש לסיים את תהליך יצירת הפרופיל באפליקציה לפני ביצוע תשלום.',
	},
	payment: {
		heading: 'פרטי תשלום',
		subheading: 'הזן את פרטי כרטיס האשראי שלך',
		form: {
			firstName: 'שם פרטי',
			lastName: 'שם משפחה',
			email: 'אימייל',
			socialID: 'ת"ז',
			phoneNumber: `מס' טלפון`,
			creditCardNumber: 'מספר כרטיס אשראי',
			cardExpiration: 'תוקף כרטיס אשראי',
			CVV: 'קוד אבטחה',
			requiredField: 'הינו שדה חובה',
			invalidField: 'שסופק אינו תקני',
		},
		premiumSubscription: 'ביצוע תשלום עבור מנוי פרמיום',
		premiumSubscriptionPrice: '₪19.90 /חודשי',
		confirmed: {
			title: 'חשבון הפרמיום שלך אושר',
			body: 'עכשיו אתה יכול ליהנות מכל היתרונות של חשבון הפרמיום.'
		}
	},

	errors: {
		unexpected_error: 'איראה שגיאה בלתי צפויה.',

		server_errors: {
			A0: 'איראה שגיאה בלתי צפויה. נסה שנית בקרוב.',
			A1: 'The provided ID is invalid',
			A2: 'A user with the provided ID was not found',
			A3: 'A user that is unverified cannot be manipulated',
			A4: 'The field is required',
			A6: 'The provided email is invalid',
			A9: 'The provided name is not valid. The name can contain only the following: A-Z, a-z or a whitespace with at most 3 words',
			A11: 'Deleted or unverified clients cannot be manipulated',
			A12: 'The provided phone must be an Israeli phone number with country code',
			A13: 'קיים משתמש אשר רשום באמצעות מספר הטלפון שסופק',
			A14: 'לא קיים במערכת משתמש אשר רשום באמצעות מספר הטלפון שסופק',
			A15: 'A valid name is required and may only contain the following: A-Z, a-z or a whitespace with at most 3 words',
			A16: 'Profile already created',
			A17: 'קוד האימות שסופק שגוי, יש לנסות שוב',
			A18: 'מיצת את כמות הניסיונות התחברות עם קוד האימות הנוכחי, יש לבקש קוד חדש',
			A19: 'פג התוקף של קוד האימות יש לבקש קוד חדש',
			A20: 'Minimum age standard has not been met',
			A21: 'The value is expected to be of type Date',
			A22: 'An entity with the provided ID was not found.',
			A23: 'The value is expected to be of type Boolean.',
			A24: 'The value is expected to be of type String.',
			A25: 'The provided value is out of bounds.',
			A26: 'The value is expected to be of type Array.',
			A27: 'This resource is only available for premium clients',
			A28: 'The value must not be empty.',
			A29: 'The value is expected to be of type Latitude, Longitude.',
			A30: 'The plant spot status can only be determined during "pending approval" status',
			A31: 'You are unauthorized to access the requested resource',
			A32: 'A trial subscription can only be done once.',
			A33: 'A trial subscription cannot be activated while subscribed.',
			A34: 'The value is expected to be of type credit card.',
			A35: 'A new subscription cannot be generated while already subscribed.',
			A36: 'The provided notification type is not supported.',
			A37: 'The provided data is unrecognized by PayMe.',
			A38: 'Only a single source of payment may be used to subscribe.',
			A39: 'The notification does not align with the subscription status of PayMe.',
			A40: 'Cannot unsubscribe without an active standard subscription.',
			A41: 'Sale status does not match the request.',
			A42: 'An account cannot be deleted with an active standard subscription.',
			A43: 'The attempt to manually unsubscribe the client from PayMe has failed unexpectedly.',
			A44: 'The provided expiration date must be a future date.',
			A45: 'A video link cannot be provided without a source and vice versa',
			A46: 'The client does not have an active standard or admin generated subscription',
			A47: 'An address cannot be provided without coordinates and vice verse',
			A48: 'The selected entity is invalid.',
			A49: 'Duplicate values are not allowed.',
			A50: 'The value is expected to be of type file.',
		},
	}
};
export default language;
