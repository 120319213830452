import i18n from 'i18n-js';
import he from './hebrew';

// Set the key-value pairs for the different languages you want to support.
i18n.translations = {
	he: he,
};

// Set the locale once at the beginning of your app.
// i18n.locale = Localization.locale;
i18n.locale = 'he';

export default i18n;
