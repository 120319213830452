import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, TextField} from '@material-ui/core';
import i18n from '../../localization/config';
import PayMeFieldStyles from './PayMeFieldStyles';
import './Payment.css';
import {useDispatch, useSelector} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import {Formik} from 'formik';
import {subscribe} from '../../redux/actions';
import {ReactComponent as CheckMark} from '../../assets/images/checkmark.svg';
import * as Sentry from '@sentry/react';
import {ReactComponent as LockIcon} from '../../assets/images/lock.svg';
import {ReactComponent as Checkmark} from '../../assets/images/checkmark_circle.svg';

const Payment = props => {
	const PayMe = window.PayMe;
	const dispatch = useDispatch();
	const clientProfile = useSelector(state => state.userProfile);
	const paymentSuccess = useSelector(state => state.paymentSuccess);
	const [formReady, setFormReady] = useState(false);
	const [PayMeInstance, setPayMeInstance] = useState(null);
	const [tokenizationSucceeded, setTokenizationSucceeded] = useState(false);
	const [tokenizationError, setTokenizationError] = useState('');

	// Credit card field related state
	const [creditCardFocused, setCreditCardFocused] = useState(false);
	const [creditCardValid, setCreditCardValid] = useState(false);
	const [creditCardTouched, setCreditCardTouched] = useState(false);
	const [creditCardErrorMessage, setCreditCardErrorMessage] = useState('');

	// Expiration date related state
	const [cardExpirationFocused, setCardExpirationFocused] = useState(false);
	const [cardExpirationValid, setCardExpirationValid] = useState(false);
	const [cardExpirationTouched, setCardExpirationTouched] = useState(false);
	const [cardExpirationErrorMessage, setCardExpirationErrorMessage] = useState('');

	// CVV related state
	const [CVVFocused, setCVVFocused] = useState(false);
	const [CVVValid, setCVVValid] = useState(false);
	const [CVVTouched, setCVVTouched] = useState(false);
	const [CVVErrorMessage, setCVVErrorMessage] = useState('');

	/**
	 * Setup all the protected PayMe fields. Source: https://github.com/PayMeService/payme-jsapi
	 */
	const initPayMe = async () => {
		const instance = await PayMe.create(
			process.env.REACT_APP_PAYME_SELLER_KEY,
			{
				testMode: process.env.REACT_APP_PAYME_TEST_MODE === 'true',
				language: 'he',
			}
		);

		const fields = instance.hostedFields();

		const allFieldsReady = [];

		// SETUP CARD NUMBER FIELD
		const cardNumber = fields.create(PayMe.fields.NUMBER, {
			styles: PayMeFieldStyles.defaultStyle,
			placeholder: i18n.t('payment.form.creditCardNumber'),
			messages: {
				invalid: `${i18n.t('payment.form.creditCardNumber')} ${i18n.t('payment.form.invalidField')}`,
				required: `${i18n.t('payment.form.creditCardNumber')} ${i18n.t('payment.form.requiredField')}`,
			},
		});
		cardNumber.on('focus', () => setCreditCardFocused(true));
		cardNumber.on('blur', () => {
			setCreditCardFocused(false);
			setCreditCardTouched(true);
		});
		cardNumber.on('validity-changed', (data) => {
			setCreditCardValid(data.isValid);
			setCreditCardErrorMessage(data.message ? data.message : '');
		});
		allFieldsReady.push(cardNumber.mount('#creditCardField'));

		// SETUP CARD EXPIRATION FIELD
		const expiration = fields.create(PayMe.fields.EXPIRATION, {
			styles: PayMeFieldStyles.defaultStyle,
			messages: {
				invalid: `${i18n.t('payment.form.cardExpiration')} ${i18n.t('payment.form.invalidField')}`,
				required: `${i18n.t('payment.form.cardExpiration')} ${i18n.t('payment.form.requiredField')}`,
			},
		});
		expiration.on('focus', () => setCardExpirationFocused(true));
		expiration.on('blur', () => {
			setCardExpirationFocused(false);
			setCardExpirationTouched(true);
		});
		expiration.on('validity-changed', (data) => {
			setCardExpirationValid(data.isValid);
			setCardExpirationErrorMessage(data.message ? data.message : '');
		});
		allFieldsReady.push(expiration.mount('#cardExpirationField'));

		// SETUP CVV FIELD
		const cvc = fields.create(PayMe.fields.CVC, {
			styles: PayMeFieldStyles.defaultStyle,
			placeholder: i18n.t('payment.form.CVV'),
			messages: {
				invalid: `${i18n.t('payment.form.CVV')} ${i18n.t('payment.form.invalidField')}`,
				required: `${i18n.t('payment.form.CVV')} ${i18n.t('payment.form.requiredField')}`,
			},
		});
		cvc.on('focus', () => setCVVFocused(true));
		cvc.on('blur', () => {
			setCVVFocused(false);
			setCVVTouched(true);
		});
		cvc.on('validity-changed', (data) => {
			setCVVValid(data.isValid);
			setCVVErrorMessage(data.message ? data.message : '');
		});
		allFieldsReady.push(cvc.mount('#cardCVVField'));

		Promise.all(allFieldsReady).then(() => setFormReady(true));

		setPayMeInstance(instance);
	};

	useEffect(() => {
		initPayMe();
	}, []);

	if (clientProfile && (clientProfile.premium || !clientProfile.profileCreated)) {
		return (
			<Redirect to="/"/>
		);
	}

	const Tokenize = async (data) => {
		try {
			const result = await PayMeInstance.tokenize({
				payerFirstName: data.firstName,
				payerLastName: data.lastName,
				payerEmail: data.email,
				payerPhone: data.phoneNumber,
				payerSocialId: data.socialID,
				total: {
					label: 'מנוי לוקיט',
					amount: {
						currency: 'ILS',
						value: '19.90',
					}
				}
			});

			await dispatch(subscribe(result.token));

			setTokenizationSucceeded(true);
		} catch (err) {
			if (err?.payload?.status_error_details) {
				setTokenizationError(err.payload.status_error_details);
			} else {
				Sentry.captureException({data: err});
				console.log(err);
			}
		}
	};

	return (
		<div className="pageContainer">
			<div className="contentContainer">
				{!paymentSuccess ? (
					<>
						<div className="heading">
							{i18n.t('payment.heading')}
						</div>

						<div
							className="benefitsSection"
						>
							<div
								className="benefitsTitle"
							>
								{i18n.t('home.premiumBenefits')}
							</div>
							{
								i18n.t('home.benefits').map(benefits =>
									<span
										key={benefits}
										dir="rtl"
										className="benefit"
									>
											<div>
												<Checkmark style={{width: 17, height: 17}}/>
											</div>
											<span
												className="benefitText"
											>
												{benefits}
											</span>
									</span>
								)
							}
						</div>

						<div
							className="disclaimerSection"
							dir="rtl"
						>
							{i18n.t('home.disclaimer')}
						</div>

						<div className="subheading">
							{i18n.t('payment.subheading')}
						</div>
						<Formik
							initialValues={{
								firstName: '',
								lastName: '',
								socialID: '',
								phoneNumber: '',
								email: '',
							}}
							validate={values => {
								const errors = {};

								setTokenizationError(''); // Reset PayMe error upon value change

								/**
								 * Apply tests of validation using PayMe.
								 */

								const FirstNameTestResult = PayMe.validators[PayMe.fields.NAME_FIRST].test(values.firstName);
								if (FirstNameTestResult?.required) {
									errors.firstName = i18n.t('required_field');
								} else if (FirstNameTestResult?.invalid) {
									errors.firstName = `${i18n.t('payment.form.firstName')} ${i18n.t('payment.form.invalidField')}`;
								}

								const LastNameTestResult = PayMe.validators[PayMe.fields.NAME_LAST].test(values.lastName);
								if (LastNameTestResult?.required) {
									errors.lastName = i18n.t('required_field');
								} else if (LastNameTestResult?.invalid) {
									errors.lastName = `${i18n.t('payment.form.lastName')} ${i18n.t('payment.form.invalidField')}`;
								}

								const socialIDTestResult = PayMe.validators[PayMe.fields.SOCIAL_ID].test(values.socialID);
								if (socialIDTestResult?.required) {
									errors.socialID = i18n.t('required_field');
								} else if (socialIDTestResult?.invalid) {
									errors.socialID = `${i18n.t('payment.form.socialID')} ${i18n.t('payment.form.invalidField')}`;
								}

								const phoneNumberTestResult = PayMe.validators[PayMe.fields.PHONE].test(values.phoneNumber);
								if (phoneNumberTestResult?.required) {
									errors.phoneNumber = i18n.t('required_field');
								} else if (phoneNumberTestResult?.invalid) {
									errors.phoneNumber = `${i18n.t('payment.form.phoneNumber')} ${i18n.t('payment.form.invalidField')}`;
								}

								const emailTestResult = PayMe.validators[PayMe.fields.EMAIL].test(values.email);
								if (emailTestResult?.required) {
									errors.email = i18n.t('required_field');
								} else if (emailTestResult?.invalid) {
									errors.email = `${i18n.t('payment.form.email')} ${i18n.t('payment.form.invalidField')}`;
								}

								return errors;
							}}
							onSubmit={async (values, {setSubmitting}) => {
								await Tokenize(values);
								setSubmitting(false);
							}}
						>
							{({
								isSubmitting,
								values,
								errors,
								touched,
								handleChange,
								handleBlur,
								handleSubmit,
							}) => (
								<form className="form" noValidate onSubmit={handleSubmit}>
									<div className="formFieldsSection">
										<div>
											<TextField
												className="formControl"
												label={i18n.t('payment.form.firstName')}
												variant="outlined"
												helperText={errors.firstName && touched.firstName ? errors.firstName : ''}
												error={(errors.firstName && touched.firstName)}
												name="firstName"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.firstName}
												fullWidth
											/>
										</div>
										<div>
											<TextField
												className="formControl"
												label={i18n.t('payment.form.lastName')}
												variant="outlined"
												helperText={errors.lastName && touched.lastName ? errors.lastName : ''}
												error={(errors.lastName && touched.lastName)}
												name="lastName"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.lastName}
												fullWidth
											/>
										</div>
										<div>
											<TextField
												className="formControl"
												label={i18n.t('payment.form.socialID')}
												variant="outlined"
												helperText={errors.socialID && touched.socialID ? errors.socialID : ''}
												error={(errors.socialID && touched.socialID)}
												name="socialID"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.socialID}
												inputProps={{
													maxLength: 9
												}}
												fullWidth
											/>
										</div>
										<div>
											<TextField
												className="formControl"
												label={i18n.t('payment.form.phoneNumber')}
												variant="outlined"
												helperText={errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : ''}
												error={(errors.phoneNumber && touched.phoneNumber)}
												name="phoneNumber"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.phoneNumber}
												inputProps={{
													maxLength: 13
												}}
												fullWidth
											/>
										</div>
										<div>
											<TextField
												className="formControl"
												label={i18n.t('payment.form.email')}
												variant="outlined"
												helperText={errors.email && touched.email ? errors.email : ''}
												error={(errors.email && touched.email)}
												name="email"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.email}
												fullWidth
											/>
										</div>
										<div className="formControl">
											<div id="creditCardField"
												 className={`PayMeField ${creditCardFocused ? 'PayMeFieldFocused' : ''} ${!creditCardValid && creditCardTouched ? 'PayMeFieldInvalid' : ''}`}/>
											{!creditCardValid && creditCardTouched && (
												<span
													className="errorText">{creditCardErrorMessage ? creditCardErrorMessage : `${i18n.t('payment.form.creditCardNumber')} ${i18n.t('payment.form.requiredField')}`}
												</span>
											)}
										</div>

										<div className="formControl">
											<div className="row">
												<span id="cardExpirationField"
													  className={`PayMeField ${cardExpirationFocused ? 'PayMeFieldFocused' : ''} ${!cardExpirationValid && cardExpirationTouched ? 'PayMeFieldInvalid' : ''}`}/>
												<span id="cardCVVField"
													  className={`PayMeField ${CVVFocused ? 'PayMeFieldFocused' : ''} ${!CVVValid && CVVTouched ? 'PayMeFieldInvalid' : ''}`}
												/>
											</div>
											<div>
												{!cardExpirationValid && cardExpirationTouched && (
													<div
														className="errorText">{cardExpirationErrorMessage ? cardExpirationErrorMessage : `${i18n.t('payment.form.cardExpiration')} ${i18n.t('payment.form.requiredField')}`}
													</div>
												)}
												{!CVVValid && CVVTouched && (
													<div
														className="errorText">{CVVErrorMessage ? CVVErrorMessage : `${i18n.t('payment.form.CVV')} ${i18n.t('payment.form.requiredField')}`}
													</div>
												)}
											</div>
										</div>
									</div>

									{(!formReady || isSubmitting) && (
										<div className="loaderRow">
											<CircularProgress/>
										</div>
									)}

									{tokenizationError && (
										<div className="errorText PayMeErrorSection">
											{tokenizationError}
										</div>
									)}

									<Button
										variant="contained"
										color="primary"
										className="premiumButton"
										type="submit"
										disabled={
											!formReady ||
											isSubmitting ||
											(!!errors.firstName || !!errors.lastName || !!errors.socialID || !!errors.email || !!errors.phoneNumber) ||
											(!touched.firstName || !touched.lastName || !touched.socialID || !touched.email || !touched.phoneNumber) ||
											tokenizationSucceeded
										}
									>
										<div
											className="buttonText"
										>
											<LockIcon style={{width: 20, height: 20}}/>
											<div className="premiumText">{i18n.t('payment.premiumSubscription')}</div>
											<div>{i18n.t('payment.premiumSubscriptionPrice')}</div>
										</div>
									</Button>
								</form>
							)}
						</Formik>
					</>
				) : (
					<div className="paymentConfirmation">
						<CheckMark/>
						<div className="heading">
							{i18n.t('payment.confirmed.title')}
						</div>
						<div className="subheading">
							{i18n.t('payment.confirmed.body')}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Payment;
